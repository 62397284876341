import * as queryString from 'query-string';

export default class LoginHelper {
    public static getSessionIdFromUrl(): string | null {
        const queryObjects: any = queryString.parse(window.location.search);

        if(queryObjects && queryObjects.sclSessionId) {
            return queryObjects.sclSessionId;
        }

        return null;
    }

    public static getClientIdFromUrl(): string | null {
        const queryObjects: any = queryString.parse(window.location.search);

        if(queryObjects && queryObjects.clientId) {
            return queryObjects.clientId;
        }

        return null;
    }

    public static getCallbackUrlFromUrl(): string | null {
        const queryObjects: any = queryString.parse(window.location.search);

        if(queryObjects && queryObjects.callbackUrl) {
            return queryObjects.callbackUrl;
        }

        return null;
    }
}