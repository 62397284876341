import React, { Fragment, useEffect } from 'react';
import { Theme, makeStyles, SnackbarContent, IconButton, Icon, withStyles, Snackbar } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import { Warning as WarningIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon, Info as InfoIcon, Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

interface ErrorToasterProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
  status: 'open' | 'close';
}

const ErrorToaster: React.FunctionComponent<ErrorToasterProps> = (props) => {
  useEffect(() => {
    const { status } = props;

    if(status === 'open') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  })

  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <Snackbar open={open}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
      </Snackbar>
    </Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default ErrorToaster;