import React, { Fragment, useEffect } from 'react';
import { withStyles, Grid, TextField, Button, Paper } from '@material-ui/core';
import classesModule from './LoginForm.module.scss';
import CognitoService from '../../services/CognitoService';
import LoginHelper from '../../helpers/login_helper/LoginHelper';
import BifrostService from '../../services/BifrostService';
import ErrorToaster from '../error_toaster/ErrorToaster';

interface LoginFormProps {
    classes: any
}

interface LoginFormState {
    username: string,
    usernameError: boolean,
    password: string
    passwordError: boolean
}

interface ErrorToasterState {
    status: 'open' | 'close',
    message?: string
}

// const cognitoService = new CognitoService();
const bifrostService = new BifrostService();

const LoginForm: React.FunctionComponent<LoginFormProps> = (props) => {
    useEffect(() => {
        validateSessionId().then((response: boolean) => {
            if (response) {
                const sessionId = LoginHelper.getSessionIdFromUrl();
                const callbackUrl = LoginHelper.getCallbackUrlFromUrl();
                // const clientId = LoginHelper.getClientIdFromUrl();

                // cognitoService.federatedADFSLogin(sessionId, clientId, callbackUrl);
                window.location.href = callbackUrl + '?state=' + sessionId + '&token=abcdef123456';
            }
        });
    })

    const { classes } = props;

    const [values, setValues] = React.useState<LoginFormState>({
        username: '',
        usernameError: false,
        password: '',
        passwordError: false
    });

    const [errorStatus, setErrorStatus] = React.useState<ErrorToasterState>({
        status: 'close'
    })

    const loginUser = async () => {
        const isValid = await validateLoginForm();

        if (isValid) {
            try {
                const sessionId = await bifrostService.login(values.username, values.password);
                const callbackUrl = LoginHelper.getCallbackUrlFromUrl();
                // const clientId = LoginHelper.getClientIdFromUrl(); # put on hold for now
    
                if (sessionId && callbackUrl) {
                    //await cognitoService.federatedADFSLogin(sessionId, clientId, callbackUrl); # put on hold for now
                    window.location.href = callbackUrl + '?state=' + sessionId + '&token=abcdef123456';
                } else {
                    console.log('no session');
                    setErrorStatus({
                        status: 'open',
                        message: 'Problem with your credential or login'
                    })
                }
            } catch (ex) {
                console.log (ex);
                setErrorStatus({
                    status: 'open',
                    message: ex.toString()
                })
            }
        } else {
            setErrorStatus({
                status: 'open',
                message: 'Missing inputs'
            })
        }
    }

    const validateSessionId = async () => {
        const sessionId = LoginHelper.getSessionIdFromUrl();

        if (sessionId) {
            const isSessionIdValid: boolean = await bifrostService.checkIfSessionIdValid(sessionId);

            return isSessionIdValid;
        }

        return false;
    }

    const validateLoginForm = async () => {
        let usernameError: boolean = false;
        let passwordError: boolean = false;

        if (!values.username) {
            console.log('no username')
            usernameError = true;
        }
        if (!values.password) {
            console.log('no password')
            passwordError = true;
        }

        setValues({
            ...values,
            usernameError: usernameError,
            passwordError: passwordError
        })

        if (usernameError || passwordError) {
            return false;
        }

        return true;
    }

    const resetLoginForm = () => {
        setValues({
            ...values,
            ['username']: '',
            ['usernameError']: false,
            ['password']: '',
            ['passwordError']: false,
        })
    }

    const handleChange = (name: keyof LoginFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [name]: event.target.value
        })
    }

    const closeErrorToaster = () => {
        console.log('close error toaster');
        setErrorStatus({
            status: 'close'
        });
    }

    return (
        <Fragment>
            <div className={classes.container}>
                <img className={classes.containerLogo} src='/bifrost-logo.png' />

                <p>Login using your Black System credentials</p>

                <Paper className={classes.formContainer}>
                    <form >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    value={values.username}
                                    fullWidth={true}
                                    placeholder='Username'
                                    required={true}
                                    error={values.usernameError}
                                    onChange={handleChange('username')} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={values.password}
                                    fullWidth={true}
                                    type='password'
                                    placeholder='Password'
                                    required={true}
                                    error={values.passwordError}
                                    onChange={handleChange('password')} />
                            </Grid>
                            <Grid item xs={12} >
                                <div className={classesModule['action-control-container']} >
                                    <Button variant="contained" color="primary" size="medium" onClick={loginUser}>Login</Button>
                                    <Button variant="text" size="medium" onClick={resetLoginForm}>Reset</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <ErrorToaster status={errorStatus.status} variant='error' onClose={closeErrorToaster} message={errorStatus.message}/>
            </div>
        </Fragment>
    )
}

const style: any = (themes: any) => ({
    container: {
        margin: '0 auto',
        marginTop: '30px',
        textAlign: 'center'
    },
    containerLogo: {
        width: '200px',
        heigth: 'auto',
        margin: '0 auto'
    },
    formContainer: {
        // border: '1px solid #ccc',
        // borderRadius: '5px',
        padding: '15px',
        textAlign: 'left',
        background: 'white'
    }
})

export default withStyles(style)(LoginForm);