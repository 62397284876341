import React, { Fragment } from 'react';
import AppRoutes from './routes/index';
import { BrowserRouter } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
