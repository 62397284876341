import React, { Fragment } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import LoginForm from '../components/login_form/LoginForm';
import classesModule from './LoginPage.module.scss';

interface LoginPageProps {
    classesModel: any
}

const LoginPage: React.FunctionComponent<LoginPageProps> = (props) => {
    return (
        <Fragment>
            <div>
                <Grid container>
                    <div className={classesModule['content']}><LoginForm /></div>

                    <div className={classesModule['footer-container']}>&copy; 2019 Topdanmark</div>
                </Grid>
            </div>
        </Fragment>
    )
}

const style: any = (theme: any) => {

}

export default withStyles(style)(LoginPage);