import axios, { AxiosRequestConfig } from 'axios';

export default class BifrostService {
    private ceptorConfig: any = null;

    constructor() {
        this.ceptorConfig = {
            serviceBase: process.env.REACT_APP_BIFROST_SERVICE_BASE  || process.env.BIFROST_SERVICE_BASE || 'https://api.dev-02.topdanmark.cloud/bifrost'
        }
    }

    public async checkIfSessionIdValid(sessionId: string) {
        if(sessionId) {
            const requestOptions: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            try {
                const bifrostResponse = await axios.get(`${this.ceptorConfig.serviceBase}/ceptor/session/validate?sclSessionId=${sessionId}`, requestOptions);
                
                if(bifrostResponse && bifrostResponse.status === 200 && bifrostResponse.data) {
                    const isValid: boolean = bifrostResponse.data.isValid;
    
                    return isValid;
                } else {
                    return false;
                }
            } catch (ex) {
                console.log(ex);
                return false;
            }
        } else {
            throw new Error('Missing required parameter');
        }
    }

    public async login(username: string, password: string) {
        // alert('node env = ' +  process.env.NODE_ENV);
        // alert('base url = ' +  process.env.REACT_APP_BIFROST_SERVICE_BASE);
        // alert('base url = ' +  process.env.BIFROST_SERVICE_BASE);

        console.log(JSON.stringify(process.env));
        
        if(username && password) {
            const requestOptions: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json'
                },
            }

            try {
                const bifrostResponse = await axios.post(`${this.ceptorConfig.serviceBase}/ceptor/session/login`,{
                    username: username,
                    password: password
                }, requestOptions);

                if(bifrostResponse && bifrostResponse.status === 200 && bifrostResponse.data && bifrostResponse.data.sessionID) {
                    console.log('login response', bifrostResponse.data);
                    const sessionId: string = bifrostResponse.data.sessionID;

                    return sessionId;
                } else {
                    return null;
                }
            } catch (ex) {
                console.log(ex);
                return null;
            }
        } else {
            throw new Error('Missing required parameters');
        }
    }

    public async loginByJwt(jwt: string) {
        if(jwt) {
            const requestOptions: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json'
                },
            }

            try {
                const bifrostResponse = await axios.post(`${this.ceptorConfig.serviceBase}/ceptor/session/login/jwt`,{
                    jwt: jwt
                }, requestOptions);

                if(bifrostResponse && bifrostResponse.status === 200 && bifrostResponse.data && bifrostResponse.data.sessionID) {
                    console.log('login response', bifrostResponse.data);
                    const sessionId: string = bifrostResponse.data.sessionID;

                    return sessionId;
                } else {
                    return null;
                }
            } catch (ex) {
                console.log(ex);
                return null;
            }
        } else {
            throw new Error('Missing required parameters');
        }
    }
}