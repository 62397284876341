import React from 'react';
import { RouteProps } from 'react-router-dom';
import LoginPage from './../pages/LoginPage';
import { Texture } from '@material-ui/icons';

const appRoutes: RouteProps[] = [
    {
        path: '/',
        component: LoginPage,
        exact: true,
    },
];

export default appRoutes;